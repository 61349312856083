<template>
  <div>
    <title>SIM INVENTORY ~ USERGROUP MANAGEMENT DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Usergroup Management Data
        <br />
        <h4>The following is a list of master usergroup management lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-4">
          <!--<router-link to="/add-supplier">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link> -->

          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Usergroup Management Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Group Name</label>
                  <input
                    type="text"
                    v-model="isidata.namagroup"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Group Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.keterangan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-else-if="isFrmeditData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Usergroup Management Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Group Name</label>
                  <input
                    type="text"
                    v-model="isidata.namagroup"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Group Name"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idusergroup"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.keterangan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-edit"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-8">
          <!--<router-link to="/add-supplier">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Plus Data </i>
            </button>
          </router-link> -->

          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Usergroup Management Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Group Name"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Group Name</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nama_group }}</td>
                      <td>{{ classdata.keterangan }}</td>
                      <td>
                        <button
                          @click="EditData(classdata.id)"
                          title="Edit User Group"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        &nbsp;
                        <router-link
                          :to="'/detailusergroup/' + classdata.nama_group"
                        >
                          <button
                            title="View Details Usergroup"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link> &nbsp;
                        <button
                          title="Delete User Group"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Group Name</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "readsup",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        idusergroup: "",
        namagroup: "",
        keterangan: "",
      },
      addData: true,
      isFrmeditData: false,
      isExist: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.isidata.namagroup = "";
      this.isidata.keterangan = "";
      this.fetchData();
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "group_user/getgroup_userbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.idusergroup = resp.data.data.id;
          this.isidata.namagroup = resp.data.data.nama_group;
          this.isidata.keterangan = resp.data.data.keterangan;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var nama = this.isidata.namagroup;
      var ket = this.isidata.keterangan;
      var idx = this.isidata.idusergroup;
      if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Group Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const paramdata = {
          nama_group: this.isidata.namagroup,
          keterangan: this.isidata.keterangan,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIUpdate = this.$apiurl + "group_user/updategroup_user/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.idusergroup = "";
              this.isidata.namagroup = "";
              this.isidata.keterangan = "";
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async saveData() {
      this.loading = true;
      var nama = this.isidata.namagroup;
      var ket = this.isidata.keterangan;
      if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Group Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const paramdata = {
          nama_group: this.isidata.namagroup,
          keterangan: this.isidata.keterangan,
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveData = this.$apiurl + "group_user/savegroup_user";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.namagroup = "";
              this.isidata.keterangan = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async fetchData() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "group_user/getalldatagroup_user?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if(resp.data.data.length == 0) {
            this.isExist = false
          } else {
            this.isExist = true
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "usergroupmanagement" });
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "group_user/getalldatagroup_user?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            })
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel = this.$apiurl + "group_user/hapusgroup_user/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              })
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
